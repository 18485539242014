import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"

const Poster = ({ image, index, eagerLoading = false }) => {
  const posterdata = useStaticQuery(graphql`
    query PosterHolderQuery {
      allFile(filter: { relativeDirectory: { eq: "posterholders" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            id
          }
        }
      }
    }
  `)

  return (
    <div className="z-10 w-full relative">
      <div
        className="absolute top-0 left-0 flex justify-center h-full w-full"
        style={{ top: "15%" }}
      >
        <div style={{ width: "60%" }}>
          <GatsbyImage
            image={image.gatsbyImageData}
            loading="eager"
            alt="A Poster"
            draggable="false"
          />
        </div>
      </div>
      <a
        href={image.url}
        className="z-20 w-full h-full"
        download
        target="_blank"
        rel="noreferrer"
      >
        <GatsbyImage
          image={
            posterdata.allFile.edges[index % 8].node.childImageSharp
              .gatsbyImageData
          }
          className="w-full"
          alt="Download This Poster"
          loading="eager"
          draggable="false"
        />
      </a>
    </div>
  )
}

export default Poster
