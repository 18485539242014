import React, { useState, useEffect } from "react"
import Div100Vh from "react-div-100vh"
import MediaQuery, { useMediaQuery } from "react-responsive"
import PropTypes from "prop-types"
import loaderMobile from "../images/gifs/loadermobile.gif"
import loaderMobilePoster from "../images/gifs/loadermobile.jpg"
import loaderDesktop from "../images/gifs/loaderdesktop.gif"

const Loader = ({ children, duration }) => {
  const [doneLoading, setDoneLoading] = useState(false)

  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay))
  }
  const isMobile = useMediaQuery({
    query: "(max-aspect-ratio: 7/8)",
  })
  useEffect(() => {
    timeout(duration).then(() => {
      setDoneLoading(true)
    })
  })

  const theLoader = () => (
    <>
      <div className="z-40 w-full h-screen top-0 left-0 fixed bg-black overflow-hidden" />
      <Div100Vh
        className="w-full h-full inset-0 flex items-center justify-center fixed z-50 overflow-hidden bg-black bg-no-repeat bg-center"
        style={
          isMobile
            ? {
                backgroundImage: `url(${loaderMobilePoster})`,
                backgroundSize: "100% 100%",
              }
            : null
        }
      >
        <MediaQuery minAspectRatio="1/1">
          <img
            src={loaderDesktop}
            className="h-full w-full pointer-events-none"
            draggable="false"
            alt=""
          />
        </MediaQuery>
        {/* <MediaQuery minAspectRatio="7/8" maxAspectRatio="3/2">
          <img
            src={loaderSquare}
            className="h-full w-full pointer-events-none"
            draggable="false"
            alt=""
          />
        </MediaQuery> */}
        <MediaQuery maxAspectRatio="1/1">
          <img
            src={loaderMobile}
            className="h-full w-full pointer-events-none"
            draggable="false"
            alt=""
          />
        </MediaQuery>
      </Div100Vh>
    </>
  )

  return !doneLoading ? (
    <>
      {theLoader()}
      <Div100Vh className="overflow-hidden">{children}</Div100Vh>
    </>
  ) : (
    children
  )
}

Loader.propTypes = {
  children: PropTypes.any.isRequired,
  duration: PropTypes.number,
}

Loader.defaultProps = {
  duration: 1000,
}

export default Loader
