import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import MediaQuery from "react-responsive"
import signGIF from "../images/gifs/sign.gif"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Poster from "../components/poster"
import backToVideo from "../images/BacktoVideoStoreLeft.jpg"
import Loader from "../components/loader"

const PosterPage = () => {
  const data = useStaticQuery(graphql`
    query PosterQuery {
      allSanityProject(sort: { order: DESC, fields: _updatedAt }) {
        edges {
          node {
            name
            poster {
              asset {
                gatsbyImageData
                url
                id
              }
            }
          }
        }
      }
    }
  `)

  const posters = () => {
    const output = []

    data.allSanityProject.edges.map((edge, index) => {
      output.push(
        <Poster
          image={edge.node.poster.asset}
          index={index}
          eagerLoading={index <= 3}
          key={`posterasp-${edge.node.poster.asset.id}`}
        />
      )
      return null
    })

    return output
  }

  return (
    <Loader duration={3000}>
      <Layout old scroll>
        <SEO title="Posters" />
        <div
          className="flex items-center justify-center flex-col"
          style={{ width: "56.25vh", maxWidth: "95%" }}
        >
          <Link
            to="/"
            className="w-5/6 sm:w-3/4 z-10 mt-4 flex items-center justify-center mb-8"
            state={{ noLoad: true }}
          >
            <img
              src={signGIF}
              alt="Movie Theatre Sign Reading Dillon Dowdell"
              className="w-full"
            />
          </Link>
        </div>

        <div className="flex items-center justify-center flex-col w-full md:px-8">
          <div className="w-3/4 md:w-full md:max-w-6xl md:grid md:grid-cols-2 lg:grid-cols-3 space-y-4 md:space-y-0">
            {posters()}
          </div>
        </div>
        <div className="flex flex-col items-center w-full">
          <MediaQuery maxWidth={639}>
            <div className="w-full flex justify-center py-4 z-30">
              <Link
                className="flex flex-row justify-start"
                style={{ width: "56.25vh", maxWidth: "95%" }}
                to="/"
                state={{ noLoad: true }}
              >
                <img
                  src={backToVideo}
                  className="h-12"
                  draggable="false"
                  alt=""
                />
              </Link>
            </div>
          </MediaQuery>
          <MediaQuery minWidth={640}>
            <div className="w-full flex justify-center py-4 px-16 z-30">
              <Link
                className="flex flex-row justify-start w-full"
                to="/"
                state={{ noLoad: true }}
              >
                <img
                  src={backToVideo}
                  className="h-20 dillon-hover"
                  draggable="false"
                  alt=""
                />
              </Link>
            </div>
          </MediaQuery>
        </div>
      </Layout>
    </Loader>
  )
}

export default PosterPage
